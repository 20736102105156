import React from 'react'
import styles from './FormBody.module.scss'
import { classNames } from '@/components/shared/classNames'

export interface FormBodyProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string
  padding?: 'none' | 'default'
}

const FormBody = ({ className, children, padding }: FormBodyProps) => {
  const paddingClass = padding ?? 'px-[1.75rem] py-[2rem]'
  return <div className={classNames(paddingClass, className, styles['form-body'])}>{children}</div>
}

export default FormBody
