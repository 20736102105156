import React from 'react'
import { classNames } from '@/components/shared/classNames'

import styles from '@/components/atoms/forms/FormGroup.module.scss'
interface FormGroupProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode
  className?: string
}

const FormGroup = ({ className, ...props }: FormGroupProps) => {
  return <div className={classNames(className, styles['form-group'])}>{props.children}</div>
}

export default FormGroup
