/* Logo */
import isotipo from './logo/isotipo.svg'
import logoLarge from './logo/logo-large.svg'
import logoSmall from './logo/logo-small.svg'
import makingItBetter from './logo/making-it-better.svg'
import globalLynxBlog from './logo/global-lynx-blog.svg'
import globalLynxLetters from './logo/global-lynx.svg'
import globalLynxBlueLetters from './logo/global-lynx-blue.svg'

/* FontAwesome */
import anglesLeft from './fontawesome/angles-left.svg'
import anglesRight from './fontawesome/angles-right.svg'
import angleDown from './fontawesome/angle-down.svg'
import arrowLeft from './fontawesome/arrow-left.svg'
import arrowRight from './fontawesome/arrow-right.svg'
import arrowUpRight from './fontawesome/arrow-up-right.svg'
import briefcase from './fontawesome/briefcase-solid.svg' // Se conserva solo la versión sólida
import calendar from './fontawesome/calendar.svg'
import caretDown from './fontawesome/caret-down.svg'
import caretLeft from './fontawesome/caret-left.svg'
import caretRight from './fontawesome/caret-right.svg'
import caretUp from './fontawesome/caret-up.svg'
import certificate from './fontawesome/certificate.svg'
import certification from './fontawesome/certification.svg'
import chalkboardUser from './fontawesome/chalkboard-user.svg'
import check from './fontawesome/check.svg'
import checkBold from './fontawesome/check-bold.svg'
import chevronUp from './fontawesome/chevron-up.svg'
import circle from './fontawesome/circle.svg'
import circleUser from './fontawesome/circle-user.svg'
import clock from './fontawesome/clock-regular.svg' // Se conserva solo la versión regular
import clockRotateLeft from './fontawesome/clock-rotate-left.svg'
import close from './fontawesome/close.svg'
import diagramProject from './fontawesome/diagram-project.svg'
import download from './fontawesome/download.svg'
import email from './fontawesome/email.svg'
import eyeSlash from './fontawesome/eye-slash.svg'
import eye from './fontawesome/eye.svg'
import facebook from './fontawesome/facebook.svg'
import fecha from './fontawesome/fecha.svg'
import folder from './fontawesome/folder.svg'
import info from './fontawesome/info.svg'
import instagram from './fontawesome/instagram.svg'
import linkedin from './fontawesome/linkedin.svg'
import location from './fontawesome/location.svg' // Se conserva solo la versión sólida
import locationDot from './fontawesome/location-dot.svg'
import menu from './fontawesome/menu.svg'
import plus from './fontawesome/plus.svg'
import phone from './fontawesome/phone-solid.svg' // Se conserva solo la versión sólida
import search from './fontawesome/search.svg'
import speaker from './fontawesome/speaker.svg'
import star from './fontawesome/star.svg'
import tag from './fontawesome/tag.svg'
import twitter from './fontawesome/twitter.svg'
import youtube from './fontawesome/youtube.svg'
import whatsapp from './fontawesome/whatsapp.svg'

// Nuevos iconos
import logout from './personalized/arrow-right-from-bracket-solid.svg'
import chevronDown from './personalized/chevron-down-solid.svg'
import chevronLeft from './personalized/chevron-left-solid.svg'
import chevronRight from './personalized/chevron-right-solid.svg'
import circleCheck from './personalized/circle-check-solid.svg'
import circleExclamation from './personalized/circle-exclamation-solid.svg'
import faceSmile from './personalized/face-smile-solid.svg'
import home from './personalized/home-regular.svg'
import odoo from './personalized/odoo-icon.svg'
import penToSquare from './personalized/pen-to-square-regular.svg'
import sortDown from './personalized/sort-down-solid.svg'
import stripe from './personalized/stripe-icon.svg'
import table from './personalized/table.svg'
import trashCan from './personalized/trash-can-regular.svg'
import triangleExclamation from './personalized/triangle-exclamation-solid.svg'
import userGroup from './personalized/user-group.svg'
import user from './personalized/user-regular.svg'
import bell from './personalized/bell.svg'
import document from './personalized/document.svg'
import course from './personalized/course.svg'
import institution from './personalized/institution.svg'
import request from './personalized/request.svg'
import studentsManagementSystem from './personalized/students-management-system.svg'
export {
  /* Logo */
  isotipo,
  logoLarge,
  logoSmall,
  makingItBetter,
  globalLynxBlog,
  globalLynxLetters,
  globalLynxBlueLetters,
  /* FontAwesome */
  anglesLeft,
  anglesRight,
  angleDown,
  arrowLeft,
  arrowRight,
  arrowUpRight,
  briefcase,
  calendar,
  caretDown,
  caretLeft,
  caretRight,
  caretUp,
  certificate,
  certification,
  chalkboardUser,
  check,
  checkBold,
  chevronUp,
  circle,
  circleUser,
  clock,
  clockRotateLeft,
  close,
  diagramProject,
  download,
  email,
  eyeSlash,
  eye,
  facebook,
  fecha,
  folder,
  info,
  instagram,
  linkedin,
  location,
  locationDot,
  menu,
  phone,
  plus,
  search,
  speaker,
  star,
  tag,
  twitter,
  youtube,
  whatsapp,
  /* Personalized */
  logout,
  chevronDown,
  chevronLeft,
  chevronRight,
  circleCheck,
  circleExclamation,
  faceSmile,
  home,
  odoo,
  penToSquare,
  sortDown,
  stripe,
  table,
  trashCan,
  triangleExclamation,
  userGroup,
  user,
  bell,
  document,
  course,
  institution,
  request,
  studentsManagementSystem
}
