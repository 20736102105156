'use client'
import React, { ChangeEvent, useState } from 'react'
import style from './FormInput.module.scss'
import FormGroup from '@/components/atoms/forms/FormGroup'
import { Label } from '@/components/atoms/forms/Label'
import InputGroup from '@/components/atoms/forms/InputGroup'
import { classNames } from '@/components/shared/classNames'

import InputErrorText from '@/components/atoms/forms/InputErrorText'
import { Icon } from '@/components/atoms/icon/Icon'

interface FormInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string
  label: string | React.ReactNode
  type:
  | 'text'
  | 'password'
  | 'email'
  | 'number'
  | 'tel'
  | 'url'
  | 'search'
  | 'date'
  | 'time'
  | 'datetime-local'
  | 'month'
  | 'week'
  placeholder: string
  defaultValue?: string | number
  value: string | number
  isValid?: boolean
  errorText?: string
  required?: boolean
  onChange: (event: ChangeEvent<HTMLInputElement>) => any
}

const FormInput = React.forwardRef(
  (
    {
      name,
      label,
      placeholder,
      type,
      value,
      defaultValue,
      isValid = true,
      required,
      disabled,
      className,
      errorText,
      onChange
    }: FormInputProps,
    ref
  ) => {
    const [isFocused, setIsFocused] = useState(false)
    const [showPass, setShowPass] = useState(false)

    const handleFocus = () => {
      setIsFocused(true)
    }

    const handleBlur = () => {
      setIsFocused(false)
    }

    const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
      onChange(event)
    }

    const isPassword = type === 'password'
    const inputType = type === 'password' && showPass ? 'text' : type

    return (
      <FormGroup className={className}>
        <Label
          isFocused={isFocused}
          isValid={isValid}
          hidden={Boolean(label)}
          htmlFor={name}
          required={required}
          className={disabled ? style['label--disabled'] : ''}
        >
          {label}
        </Label>
        <InputGroup>
          <input
            className={classNames(style.input, isValid ? '' : style['input--invalid'], disabled ? style['input--disabled'] : '')}
            // id={name}
            name={name}
            autoComplete="off"
            value={value}
            type={inputType}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={handleOnChange}
            disabled={disabled}
            placeholder={isFocused ? '' : required ? '* ' + placeholder : placeholder}
          />
          {!disabled && isPassword && (
            <button
              type="button"
              className={style['input--toggle-password']}
              onClick={() => {
                setShowPass(!showPass)
              }}
            >
              <Icon name={showPass ? 'eyeSlash' : 'eye'} title={showPass ? 'Ocultar' : 'Mostrar'} />
            </button>
          )}
          <InputErrorText>{errorText}</InputErrorText>
        </InputGroup>
      </FormGroup>
    )
  }
)

FormInput.displayName = 'FormInput'
export default FormInput
