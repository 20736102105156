import React from 'react'
import './Loader.css'
import { classNames } from '../shared/classNames'

interface Props {
  position?: string
  className?: string
  texts?: string[]
}

const textAnomationWave = (text: string) => {
  const textArray = text.split('')
  return textArray.map((letter, index) => (
    <span key={index} style={{ animationDelay: `${index * 0.1}s` }}>
      {letter}
    </span>
  ))
}

const Loader = ({ position = '', className, texts = [] }: Props) => {
  return (
    <div className={classNames('loading-modal', position, className)}>
      {texts.length === 0 && (
        <div className="lds-ellipsis">
          <div />
          <div />
          <div />
          <div />
        </div>
      )}
      {texts.length > 0 && (
        <>
          {texts.map((t, index) => (
            <div key={index} className="loader-text-fade">
              {t} {textAnomationWave('...')}
            </div>
          ))}
        </>
      )}
    </div>
  )
}

export default Loader
