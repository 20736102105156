import { classNames } from '@/components/shared/classNames'

import React, { useEffect, useRef, useState } from 'react'
import styles from './BasicSelect.module.scss'

const isSafari = () => {
  const chromeInAgent = navigator.userAgent.toLowerCase().includes('chrome')
  const safariInAgent = navigator.userAgent.toLowerCase().includes('safari')

  return safariInAgent && !chromeInAgent
}

export interface ISelectOption {
  value: string | number | undefined
  label: string | JSX.Element
}

interface DropdownHandlers {
  options: ISelectOption[]
  activeIndex: number
  setActiveIndex: (index: number) => void
  select: (value: string | number | undefined) => void
  namespace: string
}

const registerOpenDropdownHandlers = ({
  options,
  activeIndex,
  setActiveIndex,
  select,
  namespace
}: DropdownHandlers) => {
  const keyDownCallback = (e: KeyboardEvent) => {
    e.preventDefault()
    const keyMap: Record<string, () => void> = {
      Up: () => {
        setActiveIndex(activeIndex <= 0 ? options.length - 1 : activeIndex - 1)
      },
      ArrowUp: () => {
        setActiveIndex(activeIndex <= 0 ? options.length - 1 : activeIndex - 1)
      },
      Down: () => {
        setActiveIndex(activeIndex + 1 === options.length ? 0 : activeIndex + 1)
      },
      ArrowDown: () => {
        setActiveIndex(activeIndex + 1 === options.length ? 0 : activeIndex + 1)
      },
      Enter: () => {
        select(options[activeIndex].value)
      },
      ' ': () => {
        select(options[activeIndex].value)
      },
      Esc: () => {
        select(undefined)
      },
      Escape: () => {
        select(undefined)
      },
      PageUp: () => {
        setActiveIndex(0)
      },
      Home: () => {
        setActiveIndex(0)
      },
      PageDown: () => {
        setActiveIndex(options.length - 1)
      },
      End: () => {
        setActiveIndex(options.length - 1)
      }
    }
    if (keyMap[e.key]) {
      keyMap[e.key]()
    }
  }
  const onClick = (e: MouseEvent) => {
    if (!e.composedPath().find((evt: any) => evt.dataset && evt.dataset.namespace === `${namespace}-dropdown-root`)) {
      e.preventDefault()
      select(undefined)
    }
  }
  document.addEventListener('keydown', keyDownCallback)
  document.addEventListener('click', onClick)

  return () => {
    document.removeEventListener('keydown', keyDownCallback)
    document.removeEventListener('click', onClick)
  }
}

const registerClosedDropdownHandlers = ({
  setIsDropdownOpen,
  isFocus
}: {
  setIsDropdownOpen: (status: boolean) => any
  isFocus: boolean
}) => {
  const keyDownCallback = (e: KeyboardEvent) => {
    if (['Up', 'ArrowUp', 'Down', 'ArrowDown', 'Enter'].includes(e.key)) {
      e.preventDefault()
      if (!isFocus) setIsDropdownOpen(true)
    }
  }
  document.addEventListener('keydown', keyDownCallback)

  return () => {
    document.removeEventListener('keydown', keyDownCallback)
  }
}

interface IDropdownProps {
  options: ISelectOption[]
  value: string | number | undefined
  onChange: (value: any) => any
  namespace: string
}

const useDropdown = ({ options, value, onChange, namespace }: IDropdownProps) => {
  const [isDropdownOpen, setIsDropdownOpenInternal] = useState(false)
  const listRef = useRef<HTMLUListElement>(null)
  const [activeIndex, setActiveIndex] = useState(0)
  const [isFocus, setIsFocus] = useState(false)

  const setIsDropdownOpen = (opened: boolean) => {
    if (opened) {
      const selected = options.findIndex(o => o.value === value)
      setActiveIndex(selected < 0 ? 0 : selected)
      if (listRef.current && isSafari()) {
        requestAnimationFrame(() => {
          listRef.current?.focus()
        })
      }
    } else if (listRef.current && isSafari()) {
      requestAnimationFrame(() => {
        const previousSibling = listRef.current?.previousSibling as HTMLUListElement
        previousSibling.focus()
      })
    }
    setIsDropdownOpenInternal(opened)
  }

  const select = (newValue: string | number | undefined) => {
    if (newValue !== undefined) onChange(newValue)
    setIsDropdownOpen(false)
    const doc = document as any
    doc?.activeElement?.blur()
  }

  useEffect(() => {
    if (isDropdownOpen) {
      return registerOpenDropdownHandlers({
        activeIndex,
        setActiveIndex,
        options,
        select,
        namespace
      })
    }
    if (isFocus) {
      registerClosedDropdownHandlers({ setIsDropdownOpen, isFocus })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDropdownOpen, activeIndex, isFocus, namespace])

  return {
    isDropdownOpen,
    setIsDropdownOpen,
    activeIndex,
    setActiveIndex,
    select,
    isFocus,
    setIsFocus,
    listRef
  }
}
// OptionTemplate is an JSX.Element that will be used to render the options
export interface SelectProps {
  id: string
  value: string | number | undefined
  isValid: boolean
  required?: boolean
  options: ISelectOption[]
  placeholder?: string
  disabled?: boolean
  onChange: (value: any) => any
  onFocus?: () => any
  onBlur?: () => any
  name: string
  // eslint-disable-next-line react/require-default-props
  SelectedTemplate?: (option: ISelectOption) => JSX.Element
  // eslint-disable-next-line react/require-default-props
  OptionTemplate?: (option: ISelectOption) => JSX.Element
  className?: string
}

export const BasicSelect = ((
  {
    id,
    placeholder = 'Select...',
    isValid,
    options,
    value,
    disabled,
    name = 'default_select_namespace',
    onChange,
    onFocus,
    onBlur,
    SelectedTemplate,
    OptionTemplate,
    className
  }: SelectProps,
  ref: any
) => {
  const { isDropdownOpen, setIsDropdownOpen, activeIndex, setActiveIndex, select, setIsFocus, listRef } = useDropdown({
    options,
    value,
    onChange,
    namespace: name
  })
  const selection = options.find(o => String(o.value) === String(value))
  const handleOnFocus = (value: boolean) => {
    setIsFocus(value)
    if (value && onFocus) {
      onFocus()
    } else if (!value && onBlur) {
      onBlur()
    }
  }
  return (
    <>
      {disabled ? (
        <div className="px-3 py-1 h-12 flex items-center bg-slate-50 font-semibold text-[16px] text-gray-dark cursor-not-allowed">
          {selection?.label}
        </div>
      ) : (
        <div
          key={`${name}-dropdown-root`}
          className={classNames(className, styles.select)}
          data-namespace={`${name}-dropdown-root`}
          style={{ zIndex: isDropdownOpen ? 10 : 1 }}
        >
          <button
            type="button"
            className={classNames(
              'select border border-gray-medium bg-white px-4 py-2 rounded-md flex items-center justify-between w-full',
              className,
              styles['select--select-button'],
              isValid ? '' : styles['select--invalid']
            )}
            onClick={() => {
              setIsDropdownOpen(!isDropdownOpen)
            }}
            onFocus={() => {
              handleOnFocus(true)
            }}
            onBlur={() => {
              handleOnFocus(false)
            }}
            role="combobox"
            aria-label="Elije una opción"
            aria-haspopup="listbox"
            aria-controls={`${name}_dropdown`}
            aria-expanded={isDropdownOpen}
          >
            {SelectedTemplate && selection ? (
              <SelectedTemplate {...selection} />
            ) : (
              <span className="select-value whitespace-nowrap overflow-hidden">{selection?.label ? selection.label : placeholder}</span>
            )}
            <span className="chevron">▾</span>
          </button>
          {isDropdownOpen && (
            <ul
              className={classNames(styles['select--select-dropdown'])}
              ref={listRef}
              role="listbox"
              id={`${name}_dropdown`}
            >
              {options.map((optioData, index) => {
                return (
                  <li
                    key={`${index}-${String(optioData.value)}-${name}`}
                    id={`${name}_element_${String(optioData.value)}_dropdown`}
                    aria-selected={index === activeIndex}
                    role="option"
                    onMouseOver={() => {
                      setActiveIndex(index)
                    }}
                  >
                    <label>
                      <input
                        type="radio"
                        name={`${name}_radio_${name}`}
                        value={optioData.value}
                        className={(selection?.value === optioData.value && 'checked') || ''}
                        checked={selection?.value === optioData.value}
                        onChange={() => {
                          select(optioData.value)
                        }}
                      />
                      {OptionTemplate ? <OptionTemplate {...optioData} /> : <span>{optioData.label}</span>}
                    </label>
                  </li>
                )
              })}
            </ul>
          )}
        </div>
      )}
    </>
  )
}) as React.ForwardRefExoticComponent<SelectProps & React.RefAttributes<HTMLDivElement>>
