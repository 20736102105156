import React from 'react'
import * as icons from './icons'
import { classNames } from '@/components/shared/classNames'

import './Icon.scss'

export type Icons = keyof typeof icons
export interface IconProps extends React.HTMLAttributes<HTMLSpanElement> {
  name: Icons
  className?: string
  width?: string
  height?: string
  animation?: 'none' | 'to-right' | 'pulse' | 'spin' | 'download'
}

const animationClasses = {
  none: '',
  'to-right': 'ease-[cubic-bezier(0.4, 0, 0.2, 1)] group-hover:translate-x-1 duration-300',
  pulse: 'animate-pulse',
  spin: 'animate-spin',
  download: 'ease-[cubic-bezier(0.4, 0, 0.2, 1)] group-hover:animate-download duration-300'
}

export const Icon = ({ className = 'w-full h-full', name, animation = 'none', ...props }: IconProps) => {
  return (
    <span className={classNames('icon', className, animationClasses[animation])} {...props}>
      {icons[name]()}
    </span>
  )
}
