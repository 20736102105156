type classNameProp = string | Record<string, boolean> | undefined

export const classNames = (...classNames: classNameProp[]): string => {
  const resultClasses: string[] = []

  classNames.forEach(className => {
    if (className === undefined) {
      return
    }

    if (typeof className === 'string') {
      return resultClasses.push(className)
    }

    Object.keys(className).forEach(key => {
      if (className[key]) {
        resultClasses.push(key)
      }
    })
  })

  return resultClasses.join(' ')
}

export const removeWrapper = (htmlString: string) => {
  try {
    const wrapperTags = ['<p>', '</p>']
    const cleanedString = htmlString.replace(wrapperTags[0], '').replace(wrapperTags[1], '')
    return cleanedString
  } catch (error) {
    console.log(error)
    return htmlString
  }
}

export const truncateText = (text: string, length: number): string => {
  if (text.length <= length) return text
  return text.slice(0, length) + '...'
}
