import NextLink from 'next/link'
import React from 'react'
import { classNames } from '@/components/shared/classNames'

import styles from './Button.module.scss'

type HtmlButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  href?: undefined
}

type AnchorProps = React.AnchorHTMLAttributes<HTMLAnchorElement> & {
  href: string
}

export interface ButtonProps {
  theme?:
    | 'primary'
    | 'primary-outline'
    | 'secondary'
    | 'secondary-outline'
    | 'tertiary'
    | 'tertiary-outline'
    | 'danger'
    | 'danger-outline'
    | 'warning'
    | 'warning-outline'
    | 'success'
    | 'success-outline'
    | 'pagination'
    | 'upload-file'

  size?: 'base' | 'large' | 'small' | 'noSize'
  children: string | React.ReactNode
  type?: 'button' | 'submit' | 'reset'
  download?: boolean
  blank?: boolean
  pill?: boolean
  rightIcon?: React.ReactNode
  iconAnimationType?: 'none' | 'to-right' | 'pulse'
}

const hasHref = (props: HtmlButtonProps | AnchorProps): props is AnchorProps => 'href' in props

const buttonSizes = {
  noSize: '',
  base: 'px-4 py-2',
  small: 'px-2 py-2',
  large: 'px-6 py-3'
}

export const Button = ({
  theme = 'primary',
  size = 'base',
  className = '',
  type = 'button',
  download = false,
  blank = false,
  pill = false,
  rightIcon = null,
  iconAnimationType = 'none',
  ...props
}: (ButtonProps & HtmlButtonProps) | (ButtonProps & AnchorProps)) => {
  const enableChildHoverAnimationClass = 'group transition-all duration-300' // group class is used to enable child hover animation
  const pillClasses = pill ? 'rounded-full' : 'rounded-md'
  const componentProps = {
    className: classNames(
      enableChildHoverAnimationClass,
      styles.btn,
      styles[`btn--${theme}`],
      pillClasses,
      buttonSizes[size],
      className,
      'fade-appear'
    ),
    type,
    download,
    target: blank ? '_blank' : '_self',
    ...props
  }

  if (hasHref(componentProps)) {
    return (
      <NextLink href={componentProps.href} passHref={true} legacyBehavior={true}>
        <a {...componentProps}>
          {' '}
          {componentProps.children} {rightIcon}
        </a>
      </NextLink>
    )
  }

  return <button {...componentProps}> {componentProps.children} </button>
}
